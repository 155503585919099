import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import nkp from "../images/NKP.svg";
import SneakPeek from "../images/sneakpeek.webp";
import swissMailBox from "../images/SwissMailBox.png";
import soundOriginals from "../images/Sound Originals.png";
import famsa from "../images/famsa.png";
import sixDLabs from "../images/knack.svg";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#262b2f",
    height: "100%",
  },
  cardContainer: {
    maxWidth: "45rem",
    margin: "3rem auto",
    background: "#6c7a86",
    color: "#fff"
  },
  media: {
    height: "10%",
    width: '50%',
    margin: "0 auto"
  },
  body: {
    color: "#fff"
  }

}));

const projects = [
  {
    name: "Nordic Knowledge Partners",
    description: `A proprietary Mergers and Acquisitions research firm that focuses on delivering up-to-date structured data to its clients in the Nordics region. I have helped automate financial data gathering, ChatGPT integration for processing investment-based news articles, pull data from third-party equity trading platforms, and generate reports for their clients. I am constantly working with the NKP team to improve their data and provide a robust user experience for their clients including converting the existing Knack framework to a mobile-friendly version.`,
    image: nkp,
    link: "https://www.nordicknowledgepartners.com/"
  },
  {
    name: "SneakPeek",
    description: `A US-based genetics company that provides gender prediction for pregnancies from 6 weeks on. I work closely with the SneakPeek (recently acquired by Myriad Genetics) development team on building and maintaining databases, forms, and other functionalities on Knack which is the primary tool they use both for operations and customer interactions. We work on developing customized processes with backend JS mods and integrations with multiple other platforms such as Klaviyo, Attentive, HubSpot, etc., either through Make.com scenarios or through nodejs apps built by me.`,
    image: SneakPeek,
    link: "https://sneakpeektest.com/"
  },
  {
    name: "SwissMailBox",
    description: `As a private mailing company located in Geneva, I helped SwissMailBox develop a privacy-focused and easy-to-use Knack implementation for both their end-users and internal processes from scratch. As a small organization with no technical know-how, I helped guide them with perfect solutions for their use cases and built a back-end framework that required minor maintenance and low overhead.`,
    image: swissMailBox,
    link: "https://swissmailbox.biz/"
  },
  {
    name: "Fusion and Machinery Surveyors",
    description: `An Australian company that provides insurance assessments for claims, I developed easy resources for the team to be able to generate reports using js modules and automate components of their invoicing processes on Xero using make scenarios.`,
    image: famsa,
    link: "https://famsa.com.au/aboutus.htm"
  },
  {
    name: "Sound Originals",
    description: `A wedding photography freelance group that employs multiple freelancers across the US, I helped build the entire management process and custom tools for appointment and availability management, reminders, check-ins, and more by both levying tools available on the Knack platform and through custom nodejs apps.`,
    image: soundOriginals,
    link: "https://soundoriginals.com/"
  },
  {
    name: "6D Labradors",
    description: `A family-owned dog breeding company in the US, I worked closely with them, crafting their app to maintain their breeding process, manage litters, complete daily checklists, and manage the overall activities involved in managing their operation.`,
    image: sixDLabs,
    link: "https://www.6dlabradors.com/"
  },
];

const Portfolio = () => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.mainContainer}>
      <Grid container justify="center">
        {/* Projects */}
        {projects.map((project, i) => (
          <Grid item xs={12} sm={8} md={4} key={i}>
            <Card sx={{
              maxWidth: 280,
              margin: "0 auto",
              padding: "0.1em",
            }} className={classes.cardContainer}>
              <CardActionArea href={project.link} target="_blank">
                <CardMedia
                  component="img"
                  alt="Project 1"
                  height="140"
                  image={project.image}
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain", width: "100%"}}
                   className={classes.media}
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {project.name}
                  </Typography>
                  <Typography variant="body2" className={classes.body}>
                    {project.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Portfolio;
