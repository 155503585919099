import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#262b2f",
  },
  timeLine: {
    position: "relative",
    padding: "1rem",
    margin: "o auto",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "100%",
      border: "1px solid #f0dbc1",
      right: "40px",
      top: 0,
    },
    "&:after": {
      content: "''",
      display: "table",
      clear: "both",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
      "&:before": {
        left: "calc(50% - 1px)",
        right: "auto",
      },
    },
  },
  timeLineItem: {
    padding: "1rem",
    borderBottom: "2px solid #f0dbc1",
    position: "relative",
    margin: "1rem 3rem 1rem 1rem",
    clear: "both",
    "&:after": {
      content: "''",
      position: "absolute",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      right: "-0.625rem",
      top: "calc(50% - 5px)",
      borderStyle: "solid",
      borderColor: "white white transparent transparent",
      borderWidth: "0.625rem",
      transform: "rotate(45deg)",
    },
    [theme.breakpoints.up("md")]: {
      width: "44%",
      margin: "1rem",
      "&:nth-of-type(2n)": {
        float: "right",
        margin: "1rem",
        borderColor: "#f0dbc1",
      },
      "&:nth-of-type(2n):before": {
        right: "auto",
        left: "-0.625rem",
        borderColor: "transparent transparent white white",
      },
    },
  },
  timeLineYear: {
    textAlign: "center",
    maxWidth: "9.375rem",
    margin: "0 3rem 0 auto",
    fontSize: "1.8rem",
    color: "#fff",
    background: "black",
    lineHeight: 1,
    padding: "0.5rem 1rem",
    "&:before": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      margin: "0 auto",
      "&:nth-of-type(2n)": {
        float: "none",
        margin: "0 auto",
      },
      "&:nth-of-type(2n):before": {
        display: "none",
      },
    },
  },
  heading: {
    color: "#6c7a86",
    padding: "3rem 0",
    textTransform: "uppercase",
  },
  subHeading: {
    color: "#fff",
    padding: 0,
    textTransform: "uppercase",
  },
  body1: {
    color: "#6c7a86",
  },
  subtitle1: {
    color: "#f0dbc1",
    textAlign: "justify"
  },
}));

const Resume = () => {
  const classes = useStyles();
  return (
    <Box component="header" className={classes.mainContainer}>
      <Typography variant="h4" align="center" className={classes.heading}>
        Working Experience
      </Typography>
      <Box component="div" className={classes.timeLine}>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2021 - Present
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            Knack Expert
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            Knack
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}>
            From early 2021, I've worked as a freelance developer on the Knack Expert Network assisting multiple clients in developing, maintaining, and implementing advanced features for their Knack Apps.<br></br>
            I've worked with clients from multiple industries including genetics, finance, freelance management, and more.<br></br>
            My specialty in this industry is crafting simple and easy solutions either through internal tools available on the Knack platform or its backend JS customization, by deploying nodejs apps for server-side processes or using third-party integration tools such as make.com or zapier.
          </Typography>
        </Box>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2019 - 2020
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            DATA ANALYST
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            OPTIMAL NUTRITION PROTOCOL
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Developed algo for analysis of lifestyle and biological data to output nutrition and lifestyle modifications<br></br>
            Automated end to end company process<br></br>
            Developed portal system for client facing<br></br>
            Identified and set up tools for overall operations<br></br>
            Social Media Strategy and marketing
          </Typography>
        </Box>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2018 - 2020
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            DEVELOPER
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            COMBAT KINETICS
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Complete automation of operations processes using<br></br>
            SaaS Platforms and custom backend API Scripts<br></br>
            Social Media strategy and marketing through online ads<br></br>
            Sales and marketing 
            </Typography>
        </Box>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2018
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            DATA ANALYST
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            AMADORA GOURMET ICE CREAM
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Maintained inventory management software and inventory requirement prediction using production data<br></br>
            Leveraging sales data to plan product to focus on<br></br>
            Developing customer personalization using purchase patterns
          </Typography>
        </Box>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2015 - 2018
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            APPLICATION SPECIALIST - BIOINFORMATICS
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            XCODE LIFE SCIENCES
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Development of SNP prediction model for detecting<br></br>
            Type 2 Diabetes in Indian population<br></br>
            Disease risk prediction calculation using genetic data<br></br>
            Developed REST API for partners to query client report status and results<br></br>
            Operations automation using Google Apps Script and GSuite<br></br>
            Developed a program to generate PDF outputs from raw genetic data
            </Typography>
        </Box>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2014 - 2015
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            JUNIOR RESEARCH FELLOW
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            ICGEB
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Validation of Integrative Data Mining for classification of cancer stage and grade using‘ omics’ data<br></br>
            Identification of Methylation sites of PRMT protein in Plasmodium for designing molecular drug targets
          </Typography>
        </Box>
        <Typography
          variant="h2"
          className={`${classes.timeLineYear} ${classes.timeLineItem}`}
        >
          2013 - 2014
        </Typography>
        <Box component="div" className={classes.timeLineItem}>
          <Typography
            variant="h5"
            align="center"
            className={classes.subHeading}
          >
            PROJECT FELLOW
          </Typography>
          <Typography variant="body1" align="center" className={classes.body1}>
            CSIR - IGIB
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitle1}
          >
            Study of Mycobacterium virulent factors overexpression in an extrapulmonary setting
            </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Resume;
