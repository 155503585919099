import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import LinkedIn from "@material-ui/icons/LinkedIn";
import GitHub from "@material-ui/icons/GitHub";
import Knack from "../Knack.png";
import LinkIcon from "@material-ui/icons/Link";

const useStyles = makeStyles({
  bottomNavContainer: {
    background: "#222",
    height: "55px",
    overflow: "hidden",
  },
  root: {
    "& .MuiSvgIcon-root": {
      fill: "white",
      "&:hover": {
        // fill: "tomato",
        fontSize: "1.8rem",
      },
    },
  },
  knack: {
    width: "10px",
    height: "10px",
    // margin: ,
  }
});

const Footer = () => {
  const classes = useStyles();

  return (
    <BottomNavigation className={classes.bottomNavContainer}>
      <BottomNavigationAction icon={<LinkedIn />} target="_blank" href="https://www.linkedin.com/in/muthukumararjun/" className={classes.root} />
      <BottomNavigationAction icon={<GitHub />} target="_blank" href="https://github.com/l-arginine" className={classes.root} />
      <BottomNavigationAction icon={<img width="25px" src={Knack} alt={LinkIcon} className={classes.root}  />} target="_blank" href="https://www.knack.com/experts/#expert-directory/expert-details2/6151fd61e43e7e001e32d3c9/" className={classes.root} />
    </BottomNavigation>
  );
};
export default Footer;
