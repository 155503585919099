import React from "react";
import { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import axios from 'axios';

import Send from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
  contactContainer: {
    background: "#262b2f",
    height: "100vh",
  },
  heading: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: "1rem",
  },
  form: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  input: {
    color: "#fff",
  },
  button: {
    marginTop: "1rem",
    color: "#fff",
    borderColor: "#f0dbc1",
    "&:hover": {
      color: "#f0dbc1",
    }
  },
  field: {
    margin: "1rem 0rem",
  },
  disabledButton: {
    backgroundColor: 'gray'
  },
  error: {
    color: "red"
  }
}));

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& label": {
      color: "#f0dbc1",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#f0dbc1",
      },
      "&:hover fieldset": {
        borderColor: "#f0dbc1",
      },
      "&.Mui-focused fieldset": {
        color: "#fff",
        borderColor: "#f0dbc1",
      },
    }
  },
})(TextField);

const Contact = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setemail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('Please fill all fields!');

  const handleDisabled = () => {
    if (name !== "" && email !== "" && email !== false && message !== "") {
      setErrorMessage("");
    } else {
      if (email === false) {
        setErrorMessage("Please ensure the email field is filled correctly!");
      }
    }
  }

  const handleClick = () => {

    setErrorMessage("submitting...");

    let nameSplit = {
      first: name.split(" ")[0],
      last: (name.split(" ")[1] !== undefined ? name.split(" ")[1] : "")
    };

    axios({
      url: "https://api.knack.com/v1/pages/scene_17/views/view_22/records/",
      method: "POST",
      headers: {
        'X-Knack-Application-Id': `64c0ad046e0781002abed364`,
        'X-Knack-REST-API-Key': `knack`,
        'Content-Type': 'application/json',
        "Cache-Control": `no-cache`
      },
      data: JSON.stringify({
        field_44: nameSplit,
        field_45: email,
        field_46: message
      })
    }).then((resp) => {
      console.log(resp);
      window.location.reload();
    }).catch((err) => {
      setErrorMessage("Something went wrong! Try again.");
    });
  }
  return (
    <Box component="div" className={classes.contactContainer}>
      <Grid container justify="center">
        <Box component="form" className={classes.form}>
          <Typography variant="h5" className={classes.heading}>
            Hire or Contact me...
          </Typography>
          <InputField
            fullWidth={true}
            label="Name"
            variant="outlined"
            onChange = {
              (evt) => {
                setName(evt.target.value);
                handleDisabled();
              }
            }
            inputProps={{ className: classes.input }}
          />
          <InputField
            fullWidth={true}
            label="Email"
            variant="outlined"
            onChange = {
              (evt) => {
                if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(evt.target.value)) {
                  setemail(evt.target.value);
                  handleDisabled();
                } else {
                  setemail(false);
                }
              }
            }

            inputProps={{ className: classes.input }}
            className={classes.field}
          />
          <InputField
            fullWidth={true}
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            onChange = {
              (evt) => {
                setMessage(evt.target.value);
                handleDisabled();
              }
            }
            inputProps={{ className: classes.input }}
          />
          {errorMessage && <div className={classes.error}> {errorMessage} </div>}
          <Tooltip title={errorMessage}>
            <span>
          <Button
            variant="outlined"
            fullWidth={true}
            endIcon={<Send />}
            className={classes.button}
            classes={{disabled: classes.disabledButton}}
            onClick={handleClick}
            disabled={errorMessage === "" ? null : true}
            // title={errorMessage}
          >
            Contact Me
          </Button>
            </span>
          </Tooltip>
        </Box>
      </Grid>
    </Box>
  );
};

export default Contact;
